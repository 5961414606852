import React from "react";
import { filter } from "../ChildTable";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  headerPage: {
    flexDirection: "row",
  },
  section: {
    margin: 3,
    padding: 1,
    flexGrow: 1,
    justifyContent: "center",
    width: 40,
  },
  table: {
    border: 0.5,
  },
  text: {
    fontSize: 9,
  },
  textHeader: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#000000e6",
  },
});

export default function ToPdf({ header, data, filter_filterMany }) {
  return (
    <Document width="1100">
      <Page size="A4">
        <View style={styles.table}>
          <View style={styles.headerPage}>
            {header
              ? header.map((el, key) => {
                  if (el.visible) {
                    return (
                      <View style={styles.section}>
                        <Text style={styles.textHeader}>{el.label}</Text>
                      </View>
                    );
                  }
                })
              : null}
          </View>

          {filter(data, "", header, filter_filterMany).map((el, key) => {
            return (
              <View style={styles.page}>
                {header.map((head) => {
                  if (head.visible) {
                    return (
                      <View style={styles.section}>
                        {typeof el[head.name] == "string" ||
                        typeof el[head.name] == "number" ? (
                          <Text style={styles.text}>
                            {el[head.name] == "null" ? "" : el[head.name]}
                          </Text>
                        ) : typeof el[head.name] == "object" ? (
                          <Text style={styles.text}> </Text>
                        ) : null}
                      </View>
                    );
                  }
                })}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}
