import React from "react";

const ModalTable = (props) => {
  const { title, children, footer, size, id } = props;

  return (
    <div
      class="modal fade"
      id={id}
      tabindex="-1"
      aria-labelledby={id}
      aria-hidden="true"
    >
      <div class={"modal-dialog " + size}>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {title}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">{children}</div>
          {footer && <div class="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export default ModalTable;
