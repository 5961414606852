import React from "react";
import Nav from "../../components/Nav";
import DataTable from "../../components/DataTable";
import Tia from "../../constants/Api";
import ButtonLoader from "../../components/ButtonLoader";

import SecteurComponent from "../../components/SecteurComponent";
import TitleComponent from "../../components/TitleComponent";
import SkillsComponent from "../../components/SkillsComponent";
import Classification from "../../components/Classification";
import Etude from "../../components/Etude";
import Header from "../../components/Header";
// import $ from "jquery";

const Setting = () => {
  const [secteur, setSecteur] = React.useState([]);
  const [title, setTitle] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [categorys, setCategory] = React.useState([]);
  const [etudes, setEtudes] = React.useState([]);

  const [loader, setLoader] = React.useState(true);
  const [loader2, setLoader2] = React.useState(true);
  const [loader3, setLoader3] = React.useState(true);
  const [loader4, setLoader4] = React.useState(true);
  const [loader5, setLoader5] = React.useState(true);

  const [isLoader, setIsLoader] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    Headers();
  }, []);

  function Headers() {
    Tia.Secteur.get().then((res) => {
      if (res.data) {
        setSecteur(res.data);
        setLoader(false);
      }
    });

    Tia.Job.jobtitle().then((res) => {
      if (res.data) {
        setTitle(res.data);
        setLoader2(false);
      }
    });

    Tia.Skill.get().then((res) => {
      if (res.data) {
        setSkills(res.data);
        setLoader3(false);
      }
    });

    Tia.Classification.get().then((res) => {
      if (res.data) {
        setCategory(res.data);
        setLoader4(false);
      }
    });

    Tia.Etude.get().then((res) => {
      if (res.data) {
        setEtudes(res.data);
        setLoader5(false);
      }
    });
  }

  function addSecteurs(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    Tia.Secteur.add(formData).then((res) => {
      if (res.data) {
        // getAllSecteur()

        setSuccess(true);
        setIsLoader(false);
        Headers();
      } else {
        // getAllSecteur()
        setIsLoader(false);
        setError(true);
      }
    });
  }

  function addTitles(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    Tia.Job.addjobtitle(formData).then((res) => {
      if (res.data) {
        setIsLoader(false);
        setSuccess(true);
        Headers();
      } else {
        // getAllSecteur()
        setIsLoader(false);
        setError(true);
      }
    });
  }

  function addSkills(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    Tia.Skill.add(formData).then((res) => {
      if (res.data) {
        setIsLoader(false);
        setSuccess(true);
        Headers();
      } else {
        // getAllSecteur()
        setIsLoader(false);
        setError(true);
      }
    });
  }

  function addCategorys(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    Tia.Classification.add(formData).then((res) => {
      if (res.data) {
        // getAllSecteur()

        setIsLoader(false);
        setSuccess(true);
        Headers();
      } else {
        // getAllSecteur()
        setIsLoader(false);
        setError(true);
      }
    });
  }

  function addEtudes(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    Tia.Etude.add(formData).then((res) => {
      if (res.data) {
        // getAllSecteur()

        setSuccess(true);
        setIsLoader(false);
        Headers();
      } else {
        // getAllSecteur()
        setIsLoader(false);
        setError(true);
      }
    });
  }

  function refresh() {
    Headers();
  }

  // function deleteCategorys(el) {
  //     if (el) {

  //         Tia.Classification.delete(el).then(res=>{
  //             if(res.data){
  //                 notify(true);
  //                 Headers();

  //             }
  //         }).catch(error=>{
  //             notify(false)
  //         })
  //     }
  // }

  function renderSecteur() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          customHeader={["Nom du secteur", ""]}
          data={secteur}
          renderItem={(item, key) => (
            <SecteurComponent data={item} id={key} refresh={refresh} />
          )}
        />
      );
    }
  }

  function renderTitle() {
    if (loader2) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          customHeader={["Nom de l'emploi", ""]}
          data={title}
          renderItem={(item, key) => (
            <TitleComponent data={item} id={key} refresh={refresh} />
          )}
        />
      );
    }
  }

  function renderSkill() {
    if (loader3) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          customHeader={["Nom de la compétence", ""]}
          data={skills}
          renderItem={(item, key) => (
            <SkillsComponent data={item} id={key} refresh={refresh} />
          )}
        />
      );
    }
  }

  function renderClass() {
    if (loader4) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          customHeader={["Nom de la classe", ""]}
          data={categorys}
          renderItem={(item, key) => (
            <Classification data={item} id={key} refresh={refresh} />
          )}
        />
      );
    }
  }

  function renderEtudes() {
    if (loader5) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          customHeader={["Niveau d'étude", ""]}
          data={etudes}
          renderItem={(item, key) => (
            <Etude data={item} id={key} refresh={refresh} />
          )}
        />
      );
    }
  }

  return (
    <div>
      <Header />
      <div className="wrapper d-flex align-items-stretch">
        <Nav />

        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <h3>Reglages</h3>

            <div className="row mt-5">
              <div className="col-3">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-toggle="pill"
                    href="#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Secteurs
                  </a>

                  <a
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-toggle="pill"
                    href="#v-pills-profile"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Titre d'emplois
                  </a>

                  <a
                    className="nav-link"
                    id="v-pills-messages-tab"
                    data-toggle="pill"
                    href="#v-pills-messages"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    Compétences
                  </a>

                  <a
                    className="nav-link"
                    id="v-pills-class-tab"
                    data-toggle="pill"
                    href="#v-pills-class"
                    role="tab"
                    aria-controls="v-pills-class"
                    aria-selected="false"
                  >
                    Classification
                  </a>

                  <a
                    className="nav-link"
                    id="v-pills-etude-tab"
                    data-toggle="pill"
                    href="#v-pills-etude"
                    role="tab"
                    aria-controls="v-pills-etude"
                    aria-selected="false"
                  >
                    Etudes
                  </a>
                </div>
              </div>

              <div className="col-9">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <ul className="nav justify-content-end">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-primary btn-sm"
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="fas fa-plus mr-1" /> Ajouter
                        </a>
                      </li>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Ajouter un secteur
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={addSecteurs}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Nom du secteur
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    name="nom_secteur"
                                  />
                                </div>

                                {success ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Operation reussi!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setSuccess(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur survenue!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setError(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <ButtonLoader
                                  type="submit"
                                  title="Enregistrer"
                                  onLoad={isLoader}
                                  className="btn btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ul>

                    {renderSecteur()}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <ul className="nav justify-content-end">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-primary btn-sm"
                          href="#"
                          data-toggle="modal"
                          data-target="#title"
                        >
                          <i className="fas fa-plus mr-1" /> Ajouter
                        </a>
                      </li>

                      <div
                        className="modal fade"
                        id="title"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Ajouter un un titre d'emploi
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={addTitles}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Nom du titre
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    name="name"
                                  />
                                </div>

                                {success ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Operation reussi!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setSuccess(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur survenue!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setError(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <ButtonLoader
                                  type="submit"
                                  title="Enregistrer"
                                  onLoad={isLoader}
                                  className="btn btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ul>

                    {renderTitle()}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <ul className="nav justify-content-end">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-primary btn-sm"
                          href="#"
                          data-toggle="modal"
                          data-target="#skills"
                        >
                          <i className="fas fa-plus mr-1" /> Ajouter
                        </a>
                      </li>

                      <div
                        className="modal fade"
                        id="skills"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Ajouter d'une competences
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={addSkills}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Nom de la compétence
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    name="name"
                                  />
                                </div>

                                {success ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Operation reussi!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setSuccess(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur survenue!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setError(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <ButtonLoader
                                  type="submit"
                                  title="Enregistrer"
                                  onLoad={isLoader}
                                  className="btn btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ul>

                    {renderSkill()}
                  </div>

                  {/* interface Classification */}

                  <div
                    className="tab-pane fade"
                    id="v-pills-class"
                    role="tabpanel"
                    aria-labelledby="v-pills-class-tab"
                  >
                    <ul className="nav justify-content-end">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-primary btn-sm"
                          href="#"
                          data-toggle="modal"
                          data-target="#class"
                        >
                          <i className="fas fa-plus mr-1" /> Ajouter
                        </a>
                      </li>

                      <div
                        className="modal fade"
                        id="class"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Ajouter une classification
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={addCategorys}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Nom de la classification
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    name="category"
                                  />
                                </div>

                                {success ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Operation reussi!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setSuccess(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur survenue!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setError(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <ButtonLoader
                                  type="submit"
                                  title="Enregistrer"
                                  onLoad={isLoader}
                                  className="btn btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ul>

                    {renderClass()}
                  </div>

                  {/* interface Etude */}
                  <div
                    className="tab-pane fade"
                    id="v-pills-etude"
                    role="tabpanel"
                    aria-labelledby="v-pills-etude-tab"
                  >
                    <ul className="nav justify-content-end">
                      <li className="nav-item">
                        <a
                          className="nav-link btn btn-primary btn-sm"
                          href="#"
                          data-toggle="modal"
                          data-target="#etude"
                        >
                          <i className="fas fa-plus mr-1" /> Ajouter
                        </a>
                      </li>

                      <div
                        className="modal fade"
                        id="etude"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Ajouter un niveau d'etude
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={addEtudes}>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="exampleInputEmail1">
                                    Niveau
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    name="etudes"
                                  />
                                </div>

                                {success ? (
                                  <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Operation reussi!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setSuccess(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : error ? (
                                  <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                  >
                                    <strong>Erreur survenue!</strong>
                                    <button
                                      type="button"
                                      className="close"
                                      onClick={() => setError(false)}
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className="modal-footer">
                                <ButtonLoader
                                  type="submit"
                                  title="Enregistrer"
                                  onLoad={isLoader}
                                  className="btn btn-primary"
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ul>

                    {renderEtudes()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
