import axios from "axios";
import {url} from "./constants";

const Axios = axios.create({ baseURL : url });


const Auth = {
    login(data) {
        return Axios.post("/login", data)
    }
};

const Data = {
    datagraph(data){
        return Axios.post("/search?filter="+ data, [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },

    postDatagraph(data){
        return Axios.post("/search"+data,{},{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    datagenre(data){
        return Axios.post("/search?filter="+ data, [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }

}
const User = {

    get(){
        return Axios.get("/users")
    },
    affectation(data){
        return Axios.post("/affectations",data,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    delete(iduser){
        return Axios.post("/users/" + iduser + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
};

const Entreprise = {

    get(){
        return Axios.get("/entreprises")
    },
    update(id, etat){

        return Axios.post("/entreprises/"+id+"?_method=PUT", etat);
    },
    getbyid(id) {
        return Axios.get("/entreprises/"+id)
    },
    updateRecru(id, data){
        return Axios.post("/contactes/"+id+"?_method=PUT", data,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        });
    }
};

const Job = {

    get(){
        return Axios.get("/jobs/admins")
    },
    jobtitle(){
        return Axios.get("/job_titles")
    },
    addjobtitle(data){
        return Axios.post("/job_titles",data, {
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    deletejobtitle(id){
        return Axios.post("/job_titles/" + id + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    update(id, etat) {
        return Axios.post("/jobs/"+id+"?_method=PUT", etat,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        });
    },
    delete (id,data){
        return Axios.post("/jobs/"+id+"?_method=PUT",data,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        });
    }
};

const Demande = {

    get(){
        return Axios.get("/postules")
    },
    getbyjob (id) {
        return Axios.get("/jobs/"+id)
    },

    updateDemandeur(id, data){
        return Axios.post("/postules/"+id+"?_method=PUT", data,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        });
    }
};

const Secteur = {
    get (){
        return Axios.get("/secteurs")
    },
    add(data){
        return Axios.post("/secteurs",data, {
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    delete(id) {
        return Axios.post("/secteurs/" + id + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
};

const Skill = {
    get (){
        return Axios.get("/skills")
    },
    add(data){
        return Axios.post("/skills",data, {
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    delete(id) {
        return Axios.post("/skills/" + id + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
};

const Classification = {
    get (){
        return Axios.get("/category_affectation")
    },
    add(data){
        return Axios.post("/category_affectation",data, {
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    delete(id) {
        return Axios.post("/category_affectation/" + id + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
};

const Etude = {
    get (){
        return Axios.get("/etudes")
    },
    add(data){
        return Axios.post("/etudes",data, {
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    delete(id) {
        return Axios.post("/etudes/" + id + "?_method=DELETE", [],{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
};

const Affectations = {
    get(){
        return Axios.get("/affectations")
    },
    update(id, data){
        return Axios.post("/affectations/"+ id + "?_method=PUT", data ,{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    },
    desaffecter(id){
        return Axios.post("/affectations/"+ id + "?_method=DELETE",{
            headers:{
                Authorization:sessionStorage.getItem("token")
            }
        })
    }
}

const Tia = {Affectations ,Data,Auth,User,Entreprise,Job,Demande,Secteur, Skill, Etude,Classification};

export default Tia;
