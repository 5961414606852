import React from "react";
import Tia from "../../constants/Api";
import { notify, src } from "../../constants/constants";

const AffectationComponent = (props) => {
  const { data, id, reload } = props;
  const [send, setSend] = React.useState(false);
  const [newdate, setNewdate] = React.useState("");

  function UpdateAffectation(iduser) {
    setSend(true);
    let formData = new FormData();
    formData.append("date_fin", newdate);
    Tia.Affectations.update(iduser, formData)
      .then((res) => {
        if (res.data) {
          setSend(false);
          notify(true);
          reload(true);
        }
      })
      .catch((error) => {
        if (error) {
          setSend(false);
          notify(false);
        }
      });
  }

  function desaffecter(idaffectation) {
    setSend(true);

    Tia.Affectations.desaffecter(idaffectation)
      .then((res) => {
        if (res.data) {
          setSend(false);
          notify(true);
          reload(true);
        }
      })
      .catch((error) => {
        if (error) {
          setSend(false);
          notify(false);
        }
      });
  }

  return (
    <tr>
      <td>
        <i className="fas fa-exchange-alt" style={{ fontSize: "30px" }} />
      </td>
      <td className="text-uppercase">
        {data.date_debut ? data.date_debut.split("T")[0] : <></>}
      </td>
      <td className="text-uppercase">
        {data.date_fin ? data.date_fin.split("T")[0] : <></>}
      </td>
      <td className="text-uppercase">{data.entreprise.nom_entreprise}</td>
      <td className="text-uppercase">
        {data.user.nom + " " + data.user.prenom}
      </td>
      <td>{data.category_affectation.category}</td>

      <td className="d-flex justify-content-around">
        <button
          type="button"
          className="btn btn-success btn-sm d-inline-flex"
          data-toggle="modal"
          data-target={"#modal" + id}
        >
          <i class="fa fa-edit pt-1" aria-hidden="true"></i>{" "}
          <span className="ml-1">Modifier</span>
        </button>
        <button
          type="button"
          className="btn btn-danger btn-sm ml-2 d-inline-flex"
          onClick={() => desaffecter(data.id)}
        >
          {send ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>
              <i className="fa fa-trash pt-1" aria-hidden="true" />{" "}
              <span className="ml-1">Désaffecter</span>
            </>
          )}
        </button>

        <div
          className="modal fade"
          id={"modal" + id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Détails sur l"affectation
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="row">
                          <div className="col-12 col-md-12 d-inline-flex align-items-center align-content-center">
                            <div>
                              {data.entreprise.image ? (
                                <img
                                  src={
                                    src + "entreprises/" + data.entreprise.image
                                  }
                                  alt="..."
                                  width={40}
                                />
                              ) : (
                                "pas de logo"
                              )}
                            </div>
                            <div className="ml-3">
                              <h4 className="tia-color">
                                {data.entreprise.nom_entreprise}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-4">
                            <p>
                              Date de début :<br />
                              <span className="tia-color font-weight-bold">
                                {data.date_debut
                                  ? data.date_debut.split("T")[0]
                                  : null}
                              </span>
                            </p>
                          </div>
                          <div className="col-4">
                            <p>
                              Date de fin : <br />
                              <span className="tia-color font-weight-bold">
                                {data.date_fin
                                  ? data.date_fin.split("T")[0]
                                  : null}
                              </span>
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="formGroupExampleInput2">
                                Nouvelle date de fin
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                onChange={(e) => setNewdate(e.target.value)}
                                id="formGroupExampleInput2"
                                name="date_fin"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Annuler
                  </button>

                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={() => UpdateAffectation(data.id)}
                  >
                    Modifier
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default AffectationComponent;
