import React from 'react';
import Tia from "../../constants/Api";
import {notify} from "../../constants/constants";

const TitleComponent = (props) => {
    const {data, id, refresh} = props
    const[isDelete1, setIsDelete1] = React.useState(false)

    function delteTitles(el) {


        if (el) {
            setIsDelete1(true);
            Tia.Job.deletejobtitle(el).then(res=>{
                if(res.data){
                    setIsDelete1(false);
                    refresh();
                    notify(true);
                }
            }).catch(error=>{
                notify(false)
            })
        }
    }


    return (
        <tr key={id}>
            <td>{data.name}</td>
            <td>
                {
                    isDelete1 ?
                    <span className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </span>
                    :
                    <button className="btn btn-outline-danger btn-sm"
                            onClick={()=>delteTitles(data.id)} >
                        <i className="fas fa-trash-alt mr-2" />
                        Supprimer
                    </button>
                }
            </td>
        </tr>
    );
}

export default TitleComponent;
