import React from "react";
import PropTypes from "prop-types";

const ButtonLoader = (props) => {
    return (
        <>
            {props.onLoad ? (
                <button {...props} disabled>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                </button>
                ) : (
                <button {...props}>{props.title}</button>
            )}
        </>
    );
};

ButtonLoader.propTypes = {
    onLoad: (PropTypes.bool.default = false),
};

export default ButtonLoader;
