import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./styles.css";

const Nav = () => {
  const [display, setDisplay] = React.useState(0);

  function deconnexion() {
    sessionStorage.clear();
    setDisplay(1);
  }

  function redirect() {
    if (display === 1) {
      return <Redirect to="/" />;
    }
  }

  return (
    <nav id="sidebar">
      <div className="p-4">
        <ul className="list-unstyled components mb-5">
          <li className="active">
            <Link to="/dashboard">
              <span
                className="fas fa-tachometer-alt mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Dashboard
            </Link>
          </li>

          <li className="active">
            <Link to="/affectations">
              <span
                className="fa fa-sticky-note mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Gestions des affectations
            </Link>
          </li>

          <li className="active">
            <Link to="/cvs">
              <span
                className="fa fa-sticky-note mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Gestions des cv
            </Link>
          </li>

          <li className="active">
            <Link to="/entreprises">
              <span
                className="fa fa-university mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Gestions des entreprises
            </Link>
          </li>

          <li className="active">
            <Link to="/jobs">
              <span
                className="fa fa-briefcase mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Gestions des emplois
            </Link>
          </li>

          <li className="active">
            <Link to="/get">
              <span
                className="fa fa-folder mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Gestions des demandes
            </Link>
          </li>

          <li className="active">
            <Link to="/settings">
              <span
                className="fa fa-cogs mr-3"
                style={{ color: "#fff" }}
              ></span>{" "}
              Réglages
            </Link>
          </li>

          {redirect()}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
