import React from "react";
import Nav from "../../components/Nav";
import DataTable from "../../components/DataTable";
import { notify } from "../../constants/constants";
import JobComponent from "../../components/JobComponent";
import Tia from "../../constants/Api";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const Job = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getJobs();
  }, data);

  function getJobs() {
    Tia.Job.get()
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setLoader(false);
          setData(res.data);
        }
      })
      .catch(() => {});
  }

  function Reload(bool) {
    if (bool) {
      getJobs();
    }
  }

  function renderJobs() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          title="tableau de jobs"
          customHeader={[
            "",
            "Titre de l'emploie",
            "Nom de l'entreprise",
            "Ville",
            "Date d'éxpiration",
            "Statut",
            "Actions",
          ]}
          data={data}
          renderItem={(item, key) => (
            <JobComponent data={item} reload={Reload} id={key} />
          )}
        />
      );
    }
  }

  return (
    <div>
      <Header />

      <div className="wrapper d-flex align-items-stretch">
        <Nav />
        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="#">
                <h3>Gestion d'emplois</h3>
              </a>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link
                    className="nav-link btn btn-primary btn-sm text-white"
                    to="/rapport/jobs"
                  >
                    Rapport
                  </Link>
                </li>
              </ul>
            </nav>
            {renderJobs()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job;
