import React from "react";
import { Link, Redirect } from "react-router-dom";

const Header = () => {
  const [display, setDisplay] = React.useState(0);

  function deconnexion() {
    sessionStorage.clear();
    setDisplay(1);
  }

  function redirect() {
    if (display === 1) {
      return <Redirect to="/" />;
    }
  }
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
      <div className="container">
        <Link class="navbar-brand" to="/dashboard">
          <img
            src="http://job.group-tia.com//tia.png"
            alt="logo du Groupe TIA"
            width="60"
          />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {sessionStorage.getItem("userFullname")}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="#" onClick={deconnexion}>
                  Déconnexion
                </a>
                {redirect()}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
