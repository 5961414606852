import React from "react";

const FooterTable = ({ number, onPageChange, previous, next }) => {
  return (
    <div className="d-flex  justify-content-between p-2">
      <div className="form-group">
        <select
          className="form-control"
          id="exampleFormControlSelect1"
          onChange={onPageChange}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>

      <div className="">
        <nav aria-label="Page navigation">
          <ul className="pagination">
            <li className="page-item" onClick={previous}>
              <a className="page-link">Precedent</a>
            </li>

            <li className="page-item">
              <a className="page-link">{number}</a>
            </li>

            <li className="page-item" onClick={next}>
              <a className="page-link">Suivant</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default FooterTable;
