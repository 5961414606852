import React, { useEffect, useState } from "react";
import Nav from "../../components/Nav";
import Tia from "../../constants/Api";
import { notify, printDocument } from "../../constants/constants";
import ButtonLoader from "../../components/ButtonLoader";

import CvComponent from "../../components/CvComponent";
import DataTable from "../../components/DataTable";
import BarbozaTable from "../../components/BarbozaTable";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const Config = [
  {
    name: "id",
    label: "Identifiant",
    visible: false,
  },
  {
    name: "sex",
    label: "Genre",
    visible: true,
  },
  {
    name: "nom",
    label: "Nom",
    visible: true,
  },
  {
    name: "postnom",
    label: "Postnom",
    visible: true,
  },
  {
    name: "prenom",
    label: "Prénom",
    visible: true,
  },

  {
    name: "phone",
    label: "Numéro de téléphone",
    visible: true,
  },
  {
    name: "email",
    label: "Adresse e-mail",
    visible: true,
  },
  {
    name: "skills",
    label: "Compétences",
    visible: true,
    options: {
      value: "name",
    },
  },
  {
    name: "adresse",
    label: "Adresse",
    visible: true,
    options: {
      value: ["avenu", "quartie", "commune", "ville"],
    },
  },
];

const Cv = () => {
  const [data, setData] = useState([]);
  const [sex, setSex] = useState(null);
  const [naissance, setNaissance] = useState(null);
  const [etudes, setEtudes] = useState(null);
  const [category, setcategory] = useState(null);
  const [nationalite, setNationalite] = useState(null);

  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [dataEtudes, setDataEtudes] = useState([]);
  const [dataCategorys, setDataCategorys] = useState([]);

  useEffect(() => {
    getCv();
    getData();
  }, []);

  function getCv() {
    // const userId = sessionStorage.getItem("userId");
    Tia.User.get().then((res) => {
      if (res.data) {
        setLoader(false);
        setData(res.data);
        // setVisible(true)
      }
    });
  }

  function getData() {
    // classification(sessionStorage.getItem("userId")).then((res) => {
    //   setDataCategorys(res.data);
    // });
    //
    // getEtudes(sessionStorage.getItem("userId")).then((res) => {
    //   // console.log(res.data)
    //   setDataEtudes(res.data);
    // });
  }

  function lockCv(element, etat) {
    const userId = sessionStorage.getItem("userId");
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("block", etat);
    // blockCv(userId, element.idUser, formData).then((res) => {
    //     console.log(res.data);
    //     if (res.data.result) {
    //         notify(true);
    //         getCv();
    //     } else {
    //         notify(false);
    //     }
    // });
  }

  function filter(event) {
    setIsLoading(true);
    const userId = sessionStorage.getItem("userId");
    let formData = new FormData();

    if (naissance != null) {
      let date = new Date();
      let y = date.getFullYear();
      let a = y - naissance;
      if (a !== date) {
        formData.append("naissance", a);
      }
    }
    if (sex != null) {
      formData.append("sex", sex);
    }

    if (etudes != null) {
      formData.append("etudes", etudes);
    }

    if (category != null) {
      formData.append("category", category);
    }

    if (nationalite != null) {
      formData.append("nationalite", nationalite);
    }

    // filtre(userId, formData)
    //     .then((res) => {
    //         console.log(res.data);
    //         setData(res.data);
    //
    //         setIsLoading(false);
    //     })
    //     .catch(setIsLoading(false));

    event.preventDefault();
  }

  function reload() {
    getCv();
  }

  function renderCv() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return data
        ? data.length > 0 && (
            <DataTable
              className="table  table-hover"
              customHeader={[
                "",
                "Prénom",
                "Nom",
                "Numéro de téléphone",
                "Actions",
              ]}
              data={data}
              title="Tableau"
              renderItem={(item, key) => (
                <CvComponent
                  data={item}
                  lock={lockCv}
                  refresh={reload}
                  id={key}
                  elementPrint={printDocument}
                />
              )}
            />
          )
        : null;
    }
  }

  return (
    <div>
      <Header />
      <div className="wrapper d-flex align-items-stretch">
        <Nav />
        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="#">
                <h3>Gestion des cv</h3>
              </a>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link
                    className="nav-link btn btn-primary btn-sm text-white"
                    to="/rapport/cvs"
                  >
                    Rapport
                  </Link>
                </li>
              </ul>
            </nav>

            <div className="row">
              <div className={visible ? "col-md-9" : "col-md-12"}>
                {renderCv()}
              </div>

              {visible ? (
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="mb-0">Filtrer : </h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={filter}>
                        <div className="form-group">
                          <label for="exampleFormControlSelect1">Genre</label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setSex(e.target.value)}
                          >
                            <option value="*">Tout</option>
                            <option>Homme</option>
                            <option>Femme</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label for="exampleFormControlSelect1">
                            Niveau d'etude
                          </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setEtudes(e.target.value)}
                          >
                            <option value="*">Tout</option>
                            {dataEtudes.map((el, key) => {
                              return <option key={key}>{el.etudes}</option>;
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <label for="exampleFormControlSelect1">
                            Classification
                          </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setcategory(e.target.value)}
                          >
                            <option value="*">Tout</option>
                            {dataCategorys.map((el, key) => {
                              return <option key={key}>{el.category}</option>;
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <label for="exampleFormControlSelect1">
                            nationalité
                          </label>
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setNationalite(e.target.value)}
                          >
                            <option value="*">Tout</option>
                            <option>Congo(RDC)</option>
                            <option>Etranger</option>
                          </select>
                        </div>

                        <div className="form-group">
                          <label for="exampleInputPassword1">Age</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputPassword1"
                            onChange={(e) => setNaissance(e.target.value)}
                          />
                        </div>

                        <ButtonLoader
                          type="submit"
                          title="Appliquer"
                          className="btn btn-primary col text-center mt-3"
                          onLoad={isLoading}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cv;
