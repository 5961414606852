import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FilterTable from "./FilterTable";
import BodyTable from "./BodyTable";
import HeaderTable from "./HeaderTable";
import FooterTable from "./FooterTable";
import ChildTable from "./ChildTable";

const BarbozaTable = ({ data, config }) => {
  const [page, setPage] = React.useState(10);
  const [number, setNumber] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [visibleRefresh, setVisibleRefresh] = React.useState();
  const [filterMany, setFilterMany] = React.useState(false);

  // initialisation of filter
  useEffect(() => {
    let rows = {};
    config.map((element) => {
      rows = { ...rows, [element.name]: [] };
    });
    setFilterMany(rows);
  }, []);

  function pages(page_data, page_page) {
    return (
      Math.trunc(page_data.length / page_page) +
      (page_data.length % page_page !== 0 ? 1 : 0)
    );
  }

  function next(next_data, next_page) {
    if (number < pages(next_data, next_page)) {
      setNumber(number + 1);
    }
  }

  function previous() {
    if (number > 1) {
      setNumber(number - 1);
    }
  }

  function onPageChange(e) {
    setPage(e.target.value);
    setNumber(1);
  }

  function onUpdateVisible(e) {
    const index = config.filter((el) => {
      return el.name === e.target.name;
    });

    config[config.indexOf(index[0])].visible = !config[config.indexOf(index[0])]
      .visible;
    console.log("in");
    setVisibleRefresh(
      config[config.indexOf(index[0])].name + new Date().getTime()
    );
  }

  console.log("sas");

  function onUpdateFilterMany(e) {
    const newFilter = filterMany[e.target.name].filter(
      (element) => element !== e.target.value
    );
    newFilter.push(e.target.value);
    setFilterMany({ ...filterMany, [e.target.name]: newFilter });
  }

  function onDeleteFilterMany(name, value) {
    const newFilter = filterMany[name].filter((element) => element !== value);
    setFilterMany({ ...filterMany, [name]: newFilter });
  }

  return (
    <div className="card">
      <FilterTable
        datas={data}
        config={config}
        onSearch={setSearch}
        onUpdateVisible={onUpdateVisible}
        onUpdateFilterMany={onUpdateFilterMany}
        filter_filterMany={filterMany}
      />
      <table className="table table-hover">
        <HeaderTable
          datas={config}
          filterMany={filterMany}
          onDeleteFilterMany={onDeleteFilterMany}
        />
        <BodyTable>
          <ChildTable
            datas={data}
            config={config}
            number={number}
            page={page}
            search={search}
            filterMany={filterMany}
          />
        </BodyTable>
      </table>
      <FooterTable
        number={number}
        onPageChange={onPageChange}
        previous={previous}
        next={() => next(data, page)}
      />
    </div>
  );
};
BarbozaTable.propTypes = {
  data: PropTypes.array,
  config: PropTypes.array.isRequired,
};
BarbozaTable.defaultProps = {
  data: [],
};

export default BarbozaTable;
