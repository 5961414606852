import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'


import Home from './views/Home';
import Login from './views/Login';
import Cv from './views/Cv';
import Entreprise from './views/Entreprise';
import Job from './views/Job';
import Get from './views/Get';
import Setting from './views/Setting';
import Rapport from './views/Rapport';
import Affectations from "./views/Affectations";

function App() {


    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route path="/affectations" component={Affectations}/>
                <Route path="/dashboard" component={Home}/>
                <Route path="/cvs" component={Cv}/>
                <Route path="/entreprises" component={Entreprise}/>
                <Route path="/jobs" component={Job}/>
                <Route path="/get" component={Get}/>
                <Route path="/settings" component={Setting}/>
                <Route path="/rapport/:para" component={Rapport}/>
            </Switch>
        </Router>


    );
}

export default App;
