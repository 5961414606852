import React from "react";
import ModalTable from "../ModalTable";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import ToPdf from "../ToPdf";

const FilterTable = ({
  onSearch,
  datas,
  config,
  onUpdateVisible,
  onUpdateFilterMany,
  filter_filterMany,
}) => {
  const [search, setSearch] = React.useState(false);
  const [preview, setPreview] = React.useState(false);

  return (
    <div className="d-flex justify-content-between p-3">
      <div>
        {search ? (
          <div className="form-group">
            <input
              type="text"
              onChange={(e) => onSearch(e.target.value)}
              className="form-control"
            />
          </div>
        ) : null}
      </div>
      <div>
        <span
          className="fa fa-search "
          style={{ fontSize: 20 }}
          onClick={() => setSearch((e) => !e)}
        />
        <span
          className="fa fa-filter ml-3"
          style={{ fontSize: 20 }}
          data-toggle="modal"
          data-target="#filtre"
        />
        <span
          className="fa fa-print ml-3"
          style={{ fontSize: 20 }}
          data-toggle="modal"
          data-target="#print"
        />
      </div>
      <ModalTable id="filtre" size="modal-dialog-scrollable modal-xl">
        <div className="row ">
          {config.map((head, key) => {
            return (
              <div key={key} className="col-lg-3">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={head.name}
                    name={head.name}
                    defaultChecked={head.visible}
                    onChange={(e) => onUpdateVisible(e)}
                  />
                  <label className="form-check-label" htmlFor={head.label}>
                    {head.label}
                    <select
                      className="form-control"
                      id={head.label}
                      name={head.name}
                      onChange={onUpdateFilterMany}
                    >
                      {datas.map((i, j) => {
                        return (
                          <option key={j}>
                            {typeof i[head.name] !== "object"
                              ? i[head.name]
                              : Array.isArray(i[head.name])
                              ? i[head.name].map((el, elkey) => {
                                  return el[head.options.value];
                                })
                              : head && "options" in head
                              ? head.options.value.map((el, key) => {
                                  if (i[head.name]) {
                                    if (key === head.options.value.length - 1) {
                                      return i[head.name][el];
                                    }
                                    return i[head.name][el] + " | ";
                                  }
                                })
                              : null}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </ModalTable>

      <ModalTable
        id="print"
        size="modal-dialog-scrollable modal-xl"
        footer={
          <PdfFooter
            preview={preview}
            setPreview={setPreview}
            config={config}
            datas={datas}
            filter_filterMany={filter_filterMany}
          />
        }
      >
        <div className="row">
          <div className="col">
            <PDFViewer width="1100" height="700">
              <ToPdf
                header={config}
                data={datas}
                filter_filterMany={filter_filterMany}
              />
            </PDFViewer>
          </div>
        </div>
      </ModalTable>
    </div>
  );
};

const PdfFooter = ({
  preview,
  setPreview,
  config,
  datas,
  filter_filterMany,
}) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-secondary "
        onClick={() => {
          setPreview((e) => !e);
        }}
      >
        <span
          className={preview ? "fa fa-eye-slash" : "fa fa-eye"}
          style={{ fontSize: 20 }}
        />
        {preview ? " Arreter la previsualisation" : " Previsualiser en pdf"}
      </button>

      <PDFDownloadLink
        document={
          <ToPdf
            header={config}
            data={datas}
            filter_filterMany={filter_filterMany}
          />
        }
        fileName="listeCv.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <button type="button" className="btn btn-primary">
              <span className="fa fa-file-pdf-o" style={{ fontSize: 20 }} />{" "}
              Imprimer en pdf
            </button>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

export default FilterTable;
