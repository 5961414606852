import React from "react";

const HeaderTable = ({ datas, filterMany, onDeleteFilterMany }) => {
  return (
    <>
      <div className="p-1 mb-2">
        {filterMany
          ? datas.map((element) => {
              return filterMany[element.name].map((row, key) => {
                return (
                  <button
                    key={key}
                    type="button"
                    className="btn btn-outline-secondary btn-sm mr-1"
                    onClick={() => onDeleteFilterMany(element.name, row)}
                  >
                    {row} <span className="fa fa-window-close" />
                  </button>
                );
              });
            })
          : null}

        {/*<button type="button" class="btn btn-outline-secondary btn-sm ">*/}
        {/*Secondary*/}
        {/*</button>*/}
      </div>
      <div>
        <thead>
          <tr>
            {datas.map(
              (element, key) =>
                element.visible && (
                  <th key={key} scope="col">
                    {element.label}
                  </th>
                )
            )}
          </tr>
        </thead>
      </div>
    </>
  );
};

export default HeaderTable;
