import React from 'react';
import Tia from "../../constants/Api";
import {notify} from "../../constants/constants";

const Etude = (props) => {

    const[isDelete, setIsDelete] = React.useState(false)
    const {data, id, refresh} = props

    function delteEtude(el) {
        if (el) {
            setIsDelete(true)
            Tia.Etude.delete(el).then(res=>{
                if(res.data){
                    setIsDelete(false);
                    refresh();
                    notify(true);
                }
            }).catch(error=>{
                notify(false)
            })
        }
    }
    return (
        <tr key={id}>
            <td>{data.etudes}</td>
            <td>
                {isDelete ?
                    <span className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </span>
                    :
                    <button className="btn btn-outline-danger btn-sm"
                            onClick={()=>delteEtude(data.id)} >
                        <i className="fas fa-trash-alt mr-2" />
                        Supprimer
                    </button>
                }
            </td>
        </tr>
    );
}

export default Etude;
