import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import BarbozaTable from "../../components/BarbozaTable";
import Tia from "../../constants/Api";

let config = [];
const Config_Cv = [
  {
    name: "id",
    label: "Identifiant",
    visible: false,
  },
  {
    name: "sex",
    label: "Genre",
    visible: true,
  },
  {
    name: "nom",
    label: "Nom",
    visible: true,
  },
  {
    name: "postnom",
    label: "Postnom",
    visible: true,
  },
  {
    name: "prenom",
    label: "Prénom",
    visible: true,
  },

  {
    name: "phone",
    label: "Numéro de téléphone",
    visible: true,
  },
  {
    name: "email",
    label: "Adresse e-mail",
    visible: true,
  },
  {
    name: "level",
    label: "Niveau d'etude",
    visible: true,
  },
  {
    name: "classification",
    label: "Classification",
    visible: true,
  },

  {
    name: "skills",
    label: "Compétences",
    visible: true,
    options: {
      value: "name",
    },
  },
  {
    name: "adresse",
    label: "Adresse",
    visible: true,
    options: {
      value: ["avenu", "quartie", "commune", "ville"],
    },
  },
];

const config_job = [
  {
    name: "id",
    label: "Identifiant",
    visible: false,
  },
  {
    name: "job_title",
    label: "titre de l'emploi",
    visible: true,
    options: {
      value: ["name"],
    },
  },
  {
    name: "active_until",
    label: "Date butoire",
    visible: true,
  },

  {
    name: "createdAt",
    label: "Date de création",
    visible: true,
  },

  {
    name: "desciption",
    label: "Description",
    visible: true,
  },

  {
    name: "createdAt",
    label: "Date de création",
    visible: true,
  },
];

const config_entreprise = [
  {
    name: "id",
    label: "Identifiant",
    visible: false,
  },
  {
    name: "nom_entreprise",
    label: "Nom de l'entreprise",
    visible: true,
  },
  {
    name: "adresse",
    label: "Adresse",
    visible: true,
    options: {
      value: ["avenu", "quartie", "commune", "ville"],
    },
  },
  {
    name: "description",
    label: "Description",
    visible: true,
  },
  {
    name: "users",
    label: "Contact",
    visible: true,
    options: {
      value: "phone",
    },
  },
];

const config_affecation = [
  {
    name: "id",
    label: "Identifiant",
    visible: false,
  },
  {
    name: "date_debut",
    label: "Date de début",
    visible: true,
  },
  {
    name: "date_fin",
    label: "Date de fin",
    visible: true,
  },
  {
    name: "user",
    label: "Cv",
    visible: true,
    options: {
      value: ["prenom", "nom"],
    },
  },
  {
    name: "entreprise",
    label: "Société",
    visible: true,
    options: {
      value: ["nom_entreprise"],
    },
  },
  {
    name: "category_affectation",
    label: "Classification",
    visible: true,
    options: {
      value: ["category"],
    },
  },
];

const Rapport = () => {
  const { para } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);

  //   get cv
  function getCv() {
    Tia.User.get()
      .then((res) => {
        let a = [];
        if (res.data) {
          res.data.forEach((element) => {
            a.push({
              ...element,
              level: element.clientEtudes[0]?.etude?.etudes,
              classification:
                element.affectations[0]?.category_affectation?.category,
            });
          });
          setData(a);
        }
        console.log(a);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function getJob(params) {
    Tia.Job.get()
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setData(res.data);
        }
      })
      .catch(() => {});
  }

  function getEntreprise(params) {
    Tia.Entreprise.get()
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setData(res.data);
        }
      })
      .catch(() => {});
  }

  function getAffectation(params) {
    Tia.Affectations.get()
      .then((res) => {
        if (res.data) {
          setLoader(false);
          setData(res.data);
        }
      })
      .catch(() => {});
  }

  useEffect(() => {
    if (para === "cvs") {
      config = Config_Cv;
      getCv();
    } else if (para === "jobs") {
      config = config_job;
      getJob();
    } else if (para === "entreprises") {
      config = config_entreprise;
      getEntreprise();
    } else if (para === "affectation") {
      getAffectation();
      config = config_affecation;
    }
  }, [para]);

  function Render() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return data ? (
        data.length > 0 ? (
          <BarbozaTable config={config} data={data} />
        ) : null
      ) : null;
    }
  }

  return (
    <div className="wrapper d-flex align-items-stretch">
      <div id="content" className="p-4 p-md-5 pt-5">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light  mb-5">
            <h3>
              <Link to="/dashboard">
                <i class="fas fa-hand-point-left"></i>
              </Link>{" "}
              Rapport
            </h3>
          </nav>

          <div className="row">
            <div className="col">{Render()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rapport;
