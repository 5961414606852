import React from "react";
import PropTypes from "prop-types";
import { LineChart, PieChart } from "react-chartkick";
import "chart.js";
import { Link } from "react-router-dom";

const Compter = (props) => {
  return (
    <>
      {props.onLoad ? (
        <div {...props}>
          <div className="card">
            <div className="card-body">
              <div className="row justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : props.isChart ? (
        props.type === "linear" ? (
          <div {...props}>
            <div className="card">
              <div className="card-body">
                <LineChart data={props.chartData} />
              </div>
            </div>
          </div>
        ) : (
          <div {...props}>
            <div className="card">
              <div className="card-body">
                <PieChart data={props.chartData} />
              </div>
            </div>
          </div>
        )
      ) : (
        <div {...props}>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <span
                  className={"fa " + props.icon + " mr-3"}
                  style={{ color: props.color, fontSize: 30 }}
                />
                <h5 className=" card-title">{props.data}</h5>
              </div>
              <h5 className="card-title text-muted">{props.title}</h5>
              {props.link && (
                <Link to={"/" + props.link} class="card-link ">
                  voir plus
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
Compter.propTypes = {
  onLoad: (PropTypes.bool.default = false),
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  isChart: (PropTypes.bool.default = false),
  chartData: PropTypes.array,
  type: PropTypes.string,
  link: PropTypes.string,
};

export default Compter;
