import React,{useEffect,useState} from "react";
import PropTypes from "prop-types";

Object.size = function (obj) {
    var size = 0,
        key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

const DataTable = (props) => {

    const { data, renderItem, customHeader, btnRight, searchbar,pagination } = props;
    const [page, setPage] = useState(10);
    const [number, setNumber] = useState(1);
    const [newTab, setNewTab] = useState([]);
    const [tab, setTab] = useState([]);

    useEffect(() => {
        setTab(data);
    });

    function paginate(array, page_size, page_number) {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    function pages() {
        return (
            Math.trunc(newTab.length / page) + (newTab.length % page !== 0 ? 1 : 0)
        );
    }

    function next() {
        if (number < pages()) {
            setNumber(number + 1);
        }
    }

    function previous() {
        if (number > 1) {
            setNumber(number - 1);
        }
    }

    function isEmpty(data) {
        for (let item in data) {
            return data;
        }
        return tab;
    }

    function filter(event) {
        if (event.length < 0) {
            setNewTab(data);
        } else {
            setNewTab(element(data, event));
        }

        //console.log(element(data, event));
    }

    function element(arr, req) {
        return arr.filter(function (el) {
            let keys = Object.keys(el);
            let len = keys.length;

            for (let i = 0; i < len; i++) {
                if (typeof el[keys[i]] === "string") {
                    if (el[keys[i]].toLowerCase().indexOf(req.toLowerCase()) !== -1) {
                        return true;
                    }
                }
            }

            return false;
        });
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    {searchbar ? (
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => filter(e.target.value)}
                            />
                        </div>
                    ) : null}
                </div>
                <div>{btnRight}</div>
            </div>
            <div className="table-responsive">
                <table {...props}>
                    <thead>
                    <tr>
                        {customHeader.map((el, key) => {
                            return <th scope="col" key={key}>{el}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {paginate(isEmpty(newTab), page, number).map((el, key) => {
                        return <>{renderItem(el, key)}</>;
                    })}
                    </tbody>
                </table>
            </div>

            {
                pagination ? (

                    <div className="d-flex  justify-content-between">
                    <div className="form-group">
                        <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => setPage(e.target.value)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={150}>150</option>
                            <option value={175}>175</option>
                        </select>
                    </div>

                    <div className="">
                        <nav aria-label="Page navigation">
                            <ul className="pagination">
                                <li className="page-item" onClick={previous}>
                                    <a className="page-link">Precedent</a>
                                </li>

                                <li className="page-item">
                                    <a className="page-link">{number}</a>
                                </li>

                                <li className="page-item" onClick={next}>
                                    <a className="page-link">Suivant</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                ) : null
            }
        </>
    );
};

DataTable.propTypes = {
    customHeader: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.array,
    ]),
    renderItem: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    btnRight: PropTypes.node,
    searchbar: PropTypes.bool,
    pagination: PropTypes.bool,
};

DataTable.defaultProps = {
    searchbar: true,
    pagination: true,
};

export default DataTable;
