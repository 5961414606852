import React from "react";
import Nav from "../../components/Nav";
import Compter from "../../components/Compter";
import DataTable from "../../components/DataTable";

import JobComponent from "../../components/JobComponent";
import Tia from "../../constants/Api";
import Header from "../../components/Header";

const Home = () => {
  const [data, setData] = React.useState([]);
  const [charts, setChart] = React.useState([]);
  const [genre, setGenre] = React.useState([]);
  const [job, setJob] = React.useState([]);
  const [entreprises, setEntreprises] = React.useState([]);

  const [load, setLoad] = React.useState(true);
  const [loader, setLoader] = React.useState(true);
  const [loader1, setLoader1] = React.useState(true);
  const [loadChart, setLoadChart] = React.useState(true);
  const [loadGenre, setLoadGenre] = React.useState(true);

  React.useEffect(() => {
    Headers();
  }, []);

  function Headers() {
    Tia.Data.datagraph("week").then((res) => {
      if (res.data) {
        setChart(res.data);
        setLoadChart(false);
        console.log(res.data);
      }
    });
    Tia.Data.datagenre("genre").then((res) => {
      if (res.data) {
        setGenre(res.data);
        setLoadGenre(false);
      }
    });
    Tia.User.get().then((res) => {
      if (res.data) {
        setData(res.data.length);
        setLoad(false);
        // setVisible(true)
      }
    });

    Tia.Entreprise.get().then((res) => {
      if (res.data) {
        let c = 0;
        res.data.forEach((element) => {
          if (element.approved == 1) {
            c += 1;
          }
        });
        setEntreprises(c);
        setLoader(false);
        // setVisible(true)
      }
    });
    Tia.Job.get().then((res) => {
      if (res.data) {
        setJob(res.data.length);
        setLoader1(false);
        // setVisible(true)
      }
    });
  }

  function charfilter(e) {
    e.preventDefault();
    setLoadChart(true);
    let params = "?filter=date&";

    if (e.target.annee.value.length !== 0) {
      params += "annee=" + e.target.annee.value;
    } else {
      params += "annee=" + new Date().getFullYear();
    }

    if (e.target.mois.value.length !== 0) {
      params += "&mois=" + e.target.mois.value;
    }

    if (e.target.du.value.length !== 0) {
      params += "&debut=" + e.target.du.value;
    }

    if (e.target.au.value.length !== 0) {
      params += "&fin=" + e.target.au.value;
    }
    Tia.Data.postDatagraph(params).then((res) => {
      if (res.data) {
        setChart(res.data);
        setLoadChart(false);
      }
    });
  }

  return (
    <div>
      <Header />
      <div className="wrapper d-flex align-items-stretch">
        <Nav />

        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <div className="row">
              <Compter
                title="Cv "
                onLoad={load}
                data={data}
                className="col-lg-4"
                icon="fa-sticky-note"
                color="green"
                link="cvs"
              />

              <Compter
                title="Entreprises "
                onLoad={loader}
                data={entreprises}
                className="col-lg-4"
                icon="fa-university"
                color="red"
                link="entreprises"
              />

              <Compter
                title="Emplois "
                onLoad={loader1}
                data={job}
                className="col-lg-4"
                icon="fa-briefcase"
                color="tomato"
                link="jobs"
              />
            </div>

            <div className="row mt-5">
              <form onSubmit={charfilter}>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col">
                      <input
                        type="number"
                        placeholder="du(jour)"
                        name="du"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        placeholder="au(jour)"
                        name="au"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        placeholder="Mois"
                        name="mois"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        placeholder="Annee"
                        name="annee"
                        className="form-control"
                      />
                    </div>
                    <div className="col">
                      <button
                        type="submit"
                        className="form-control bg-dark text-white"
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="row mt-2">
              <Compter
                title="Cv"
                onLoad={loadChart}
                className="col-lg-8"
                isChart={true}
                chartData={charts}
                type="linear"
              />
              <Compter
                title="Cv"
                onLoad={loadGenre}
                className="col-lg-4"
                isChart={true}
                chartData={genre}
                type="pie"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
