import React from "react";
import PropTypes from "prop-types";
import ButtonLoader from "../../components/ButtonLoader";
import Tia from "../../constants/Api";
import { notify } from "../../constants/constants";

const CvComponent = (props) => {
  const [entreprise, setEntreprise] = React.useState([]);
  const [dataCategory, setCategorys] = React.useState([]);
  const [isLoader, setIsLoader] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isdelete, setIsdelete] = React.useState(false);
  const [error, setError] = React.useState(false);

  const { data, id, refresh } = props;

  React.useEffect(() => {
    Headers();
  }, []);

  function Headers() {
    Tia.Entreprise.get().then((res) => {
      if (res.data) {
        setEntreprise(res.data);
      }
    });

    Tia.Classification.get().then((res) => {
      if (res.data) {
        setCategorys(res.data);
      }
    });
  }

  function affecter(event) {
    event.preventDefault();
    setIsLoader(true);
    let formData = new FormData(event.target);
    formData.append("user", data.id);

    Tia.User.affectation(formData)
      .then((res) => {
        if (res.data) {
          setIsLoader(false);
          Headers();
          notify(true);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        Headers();
        notify(false);
      });
  }

  function Delete(id) {
    setIsdelete(true);
    Tia.User.delete(id)
      .then((del) => {
        if (del.data) {
          setIsdelete(false);
          notify(true);
          refresh();
        }
      })
      .catch((err) => {
        if (err.data) {
          setIsdelete(false);
          notify(false);
        }
      });
  }
  return (
    <tr key={id}>
      <td>
        <i
          className="fas fa-user-circle"
          style={{ color: "#000", fontSize: 30 }}
        />
      </td>
      <td className="text-uppercase">{data.prenom}</td>
      <td className="text-uppercase">{data.nom}</td>
      <td>{data.phone}</td>

      <td>
        <button
          type="button"
          className="btn btn-success btn-sm d-inline-flex"
          data-toggle="modal"
          data-target={"#modal" + id}
        >
          <i className="fa fa-eye pt-1" aria-hidden="true"></i>{" "}
          <span className="ml-1">Détails</span>
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm ml-2 d-inline-flex"
          onClick={() => Delete(data.id)}
        >
          {isdelete ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>
              <i className="fa fa-trash pt-1" aria-hidden="true" />{" "}
              <span className="ml-1">Supprimer</span>
            </>
          )}
        </button>
      </td>

      <div
        className="modal fade"
        id={"modal" + id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Details sur le cv
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-5">
              <div className="row ">
                <div className="col-6">
                  <p>Nom : {data.nom}</p>
                </div>
                <div className="col-6">
                  <p>
                    Postnom :{" "}
                    {data.postnom === "null" ? "NON DEFINI" : data.postnom}
                  </p>
                </div>
                <div className="col-6">
                  <p>
                    Prénom :{" "}
                    {data.prenom === "null" ? "NON DEFINI" : data.prenom}
                  </p>
                </div>
                <div className="col-6">
                  <p>Date de naissance : {data.annive}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <p>Sexe : {data.sex}</p>
                </div>

                <div className="col-6">
                  <p>Nationalité : {data.nationalite}</p>
                </div>

                <div className="col-6">
                  <p>Email : {data.email}</p>
                </div>
                <div className="col-6">
                  <p>Téléphone : {data.phone}</p>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6">
                  <p>Compétences : </p>
                  <ul className="pl-3">
                    {data.skills.map((el, key) => {
                      return <li key={key}>{el.name}</li>;
                    })}
                  </ul>
                </div>

                <div className="col-6">
                  <p>Outils : </p>
                  <ul className="pl-3">
                    {data.outils.map((el, key) => {
                      return <li key={key}>{el.name}</li>;
                    })}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>Experiences professionnelles : </p>
                  {data.workAts.length > 0 ? (
                    <ul className="pl-3">
                      {data.workAts.map((el, index) => {
                        return (
                          <>
                            <li key={index}>
                              {el.entreprise}/{el.post} : du {el.dateDebut} au{" "}
                              {el.dateFin}
                            </li>
                            <p>
                              {el.description !== "null"
                                ? el.description
                                : null}
                            </p>
                          </>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>

                <div className="col-6">
                  <p>Formations : </p>
                  {data.clientEtudes.length > 0 ? (
                    <ul className="pl-3">
                      {data.clientEtudes.map((el, index) => {
                        return (
                          <>
                            <li key={index}>{el.etablissement}</li>
                            <p>
                              {el.description !== "null"
                                ? el.description
                                : null}
                            </p>
                          </>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>Secteurs : </p>
                  <ul className="pl-3">
                    {data.secteurs.map((el, key) => {
                      return <li key={key}>{el.nom_secteur}</li>;
                    })}
                  </ul>
                </div>
                <div className="col-6">
                  <p>Secteurs : </p>
                  {data.linguistiques.length > 0 ? (
                    <ul className="pl-3">
                      {data.linguistiques.map((el, index) => {
                        if (el.name !== "null") {
                          return (
                            <>
                              <li key={index}>
                                {el.name} ({el.niveau})
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p>Affectations : </p>
                  <ul className="pl-3 list-unstyled">
                    {data.affectations.map((el, key) => {
                      return (
                        <li key={key}>
                          {
                            <div className="row">
                              <div className="col-4">
                                <span>
                                  {" "}
                                  Entreprise : {el.entreprise.nom_entreprise}
                                </span>
                              </div>

                              <div className="col-4">
                                <span>
                                  {" "}
                                  Date de début :{" "}
                                  {el.date_debut !== null
                                    ? el.date_debut.split("T")[0]
                                    : "---"}
                                </span>
                              </div>

                              <div className="col-4">
                                <span>
                                  {" "}
                                  Date de fin :{" "}
                                  {el.date_fin !== null
                                    ? el.date_fin.split("T")[0]
                                    : "---"}
                                </span>
                              </div>
                            </div>
                          }
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <form onSubmit={affecter} id="affectation" name="affectation">
                    <div className="form-group mb-2">
                      <label htmlFor="exampleFormControlSelect1">
                        Affecter à
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="entreprise"
                      >
                        <option>Choisir ...</option>
                        {entreprise.map((el, key) => {
                          return (
                            <option value={el.id}>{el.nom_entreprise}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="form-group mb-2">
                      <label htmlFor="exampleFormControlSelect1">
                        Categories
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="category_affectation"
                      >
                        <option>Choisir ...</option>
                        {dataCategory.map((el, key) => {
                          return (
                            <option value={el.id} key={key}>
                              {el.category}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <ButtonLoader
                      type="submit"
                      title="Confirmer l'affectation"
                      onLoad={isLoader}
                      className="btn btn-primary col"
                    />
                  </form>

                  {success ? (
                    <div
                      className="alert alert-success alert-dismissible fade show mt-2"
                      role="alert"
                    >
                      <strong>Operation reussi!</strong>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setSuccess(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : error ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show mt-2"
                      role="alert"
                    >
                      <strong>Erreur survenue!</strong>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setError(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="modal-footer">
            
              {data.block === 0 ? (
                <span
                  className="fa fa-lock "
                  onClick={() => props.lock(data, 1)}
                  style={{ color: "red", fontSize: 25 }}
                />
              ) : (
                <span
                  className="fa fa-unlock "
                  onClick={() => props.lock(data, 0)}
                  style={{ color: "green", fontSize: 25 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </tr>
  );
};
CvComponent.propTypes = {
  data: PropTypes.object.isRequired,
  lock: PropTypes.func.isRequired,
  elementPrint: PropTypes.func,
};

export default CvComponent;
