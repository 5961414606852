import React from "react";
import PropTypes from "prop-types";
import {notify, src} from "../../constants/constants";
import Tia from "../../constants/Api";

const EntrepriseComponent = (props) => {
    const { data, id, reload } = props;
    const [send, setSend] = React.useState(false);

    function updateEntreprises(element, etat){

        setSend(true);
        let formData = new FormData();
        formData.append('approved',etat);
        Tia.Entreprise.update(element,formData).then(res => {
            if (res.data){
                setSend(false);
                reload(true);
                notify(true)


            }
        }).catch(error=> {
            notify(false)
        })

    };
    // React.useEffect(()=>{
    //     setSend(se)
    // })
    return (
        <tr key={id}>
            <td>
                {data.image ? (
                    <img src={src + "entreprises/" + data.image} alt="..." width={35} />
                ) : (
                    <span
                        className="fa fa-university "
                        style={{ color: "#000", fontSize: 30 }} />
                )}
            </td>

            <td>{data.nom_entreprise}</td>

            <td>
                {data.approved === "1" ? (
                    <span className="badge badge-primary">Activée</span>
                ) : data.approved === "0" ? (
                    <span className="badge badge-warning">En attente</span>
                ) : data.approved === "2" ? (
                    <span className="badge badge-danger"> Désactivée</span>
                ) : null}
            </td>

            <td>
                {data.approved === "1" ? (
                    <button
                        id={data.id}
                        type="button"
                        className="btn btn-danger btn-sm"
                        data-dismiss="modal"
                        onClick={() => updateEntreprises(data.id, 2)}
                    >
                        {send ? (
                            <span
                                id={data.id}
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Désactiver"
                        )}
                    </button>
                ) : data.approved === "0" ? (
                    <>
                        <button
                            id={data.id}
                            type="button"
                            className="btn btn-success btn-sm mr-1"
                            data-dismiss="modal"
                            onClick={() => updateEntreprises(data.id, 1)}
                        >
                            {send ? (
                                <span
                                    id={data.id}
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Activer"
                            )}
                        </button>
                        <button
                            id={data.id}
                            type="button"
                            className="btn btn-danger btn-sm"
                            data-dismiss="modal"
                            onClick={() => updateEntreprises(data.id, 2)}
                        >
                            {
                                send ? (
                                <span
                                    id={data.id}
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                "Désactiver"
                            )}
                        </button>
                    </>
                ) : data.approved === "2" ? (
                    <button
                        id={data.id}
                        type="button"
                        className="btn btn-success btn-sm"
                        data-dismiss="modal"
                        onClick={() => updateEntreprises(data.id, 1)}
                    >
                        {send ? (
                            <span
                                id={data.id}
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Activer"
                        )}
                    </button>
                ) : null}

                <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 d-inline-flex"
                    data-toggle="modal"
                    data-target={"#modal" + id}
                >
                    <i className="fa fa-eye pt-1" aria-hidden="true"></i>{" "}
                    <span className="ml-1">Détails</span>
                </button>

                <div
                    className="modal fade"
                    id={"modal" + id}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Details sur l'entreprise : {data.nom_entreprise}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-12 ">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h1 className="tia-color">{data.name}</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-12 d-inline-flex align-items-center align-content-center">
                                                    {data.image ? (
                                                        <img
                                                            src={src + "entreprises/" + data.image}
                                                            alt="..."
                                                            width={40}
                                                        />
                                                    ) : (
                                                        "pas de logo"
                                                    )}
                                                    <p className="ml-3 mb-0">{data.nom_entreprise}</p>
                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className="col-6 col-md-6">
                                                    <p>
                                                        Statut :{" "}
                                                        {data.approved === "1" ? (
                                                            <span className="text-primary">Activée</span>
                                                        ) : data.approved === "0" ? (
                                                            <span className="text-warning">En attente</span>
                                                        ) : data.approved === "2" ? (
                                                            <span className="text-danger"> Désactivée</span>
                                                        ) : null}
                                                    </p>
                                                </div>
                                                <div className="col-6 col-md-6">
                                                    <p>Code postal : {data.code_postal}</p>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-12 col-md-12">
                                                    <p className="mb-0">Addresse : {data.adresse}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row ">
                                                <div className="col-12 col-md-12 mb-2">
                                                    <p className="font-weight-bolder">
                                                        Informations sur le gerant de la société :
                                                    </p>
                                                </div>

                                                <div className="col-6 col-md-6 mb-2">
                                                    <p>Nom : {data.users[0].nom}</p>
                                                </div>
                                                <div className="col-6 col-md-6 mb-2">
                                                    <p>Prénom : {data.users[0].prenom}</p>
                                                </div>
                                                <div className="col-6 col-md-6 mb-2">
                                                    <p>Nom : {data.users[0].email}</p>
                                                </div>
                                                <div className="col-6 col-md-6 mb-2">
                                                    <p>Prénom : {data.users[0].phone}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                {data.approved === "1" ? (
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        data-dismiss="modal"
                                        onClick={() => updateEntreprises(data.id, 2)}
                                    >
                                        Désactiver
                                    </button>
                                ) : data.approved === "0" ? (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-success btn-sm mr-1"
                                            data-dismiss="modal"
                                            onClick={() => updateEntreprises(data.id, 1)}
                                        >
                                            Activer
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                            data-dismiss="modal"
                                            onClick={() => updateEntreprises(data.id, 2)}
                                        >
                                            Désactiver
                                        </button>
                                    </>
                                ) : data.approved === "2" ? (
                                    <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        data-dismiss="modal"
                                        onClick={() => updateEntreprises(data.id, 1)}
                                    >
                                        Activer
                                    </button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

EntrepriseComponent.propTypes = {
    data: PropTypes.object.isRequired,
    lock: PropTypes.func.isRequired,
};

export default EntrepriseComponent;
