import React from 'react';
import Tia from "../../constants/Api";
import {notify} from "../../constants/constants";

const SkillsComponent = (props) => {

    const[isDelete, setIsDelete] = React.useState(false)
    const {data, id, refresh} = props

    function delteSkills(el) {
        if (el) {
            setIsDelete(true);
            Tia.Skill.delete(el).then(res=>{
                if(res.data){
                    setIsDelete(false);
                    refresh();
                    notify(true);

                }
            }).catch(error=>{
                notify(false)
            })
        }
    }

    return (
        <tr key={id}>
            <td>{data.name}</td>
            <td>
                {isDelete ?
                    <span className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </span>
                    :
                    <button className="btn btn-outline-danger btn-sm"
                            onClick={()=>delteSkills(data.id)} >
                        <i className="fas fa-trash-alt mr-2" />
                        Supprimer
                    </button>
                }
            </td>
        </tr>
    );
}

export default SkillsComponent;
