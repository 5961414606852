import React from "react";
import Nav from "../../components/Nav";
import DataTable from "../../components/DataTable";
import { notify } from "../../constants/constants";
import AffectationsComponent from "../../components/AffectationsComponent";
import Tia from "../../constants/Api";
import Header from "../../components/Header";

const Affectations = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    getAffectations();
  }, data);

  function getAffectations() {
    Tia.Affectations.get().then((res) => {
      if (res.data) {
        setLoader(false);
        setData(res.data);
      }
    });
  }

  function Reload(bool) {
    if (bool) {
      getAffectations();
    }
  }

  function renderAffectations() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          title="tableau de jobs"
          customHeader={[
            "",
            "Date de début",
            "Date de fin",
            "Nom de l'entreprise",
            "Personne affectée   ",
            "Classification  ",
            "Actions",
          ]}
          data={data}
          renderItem={(item, key) => (
            <AffectationsComponent data={item} reload={Reload} id={key} />
          )}
        />
      );
    }
  }

  return (
    <div>
      <Header />
      <div className="wrapper d-flex align-items-stretch">
        <Nav />
        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="#">
                <h3>Gestion d'Affectations</h3>
              </a>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a
                    className="nav-link btn btn-primary btn-sm text-white"
                    href="/rapport/affectation"
                  >
                    Rapport
                  </a>
                </li>
              </ul>
            </nav>

            {renderAffectations()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affectations;
