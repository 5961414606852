import React from "react";
import Nav from "../../components/Nav";
import DataTable from "../../components/DataTable";

import DemanderComponent from "../../components/DemanderComponent";
import Recru from "../../components/Recru";
import Tia from "../../constants/Api";
import Header from "../../components/Header";

const Get = () => {
  const [dataDemander, setDataDemander] = React.useState([]);
  const [dataEntreprise, setDataEntreprise] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [loader2, setLoader2] = React.useState(true);

  React.useEffect(() => {
    getAllDemandes();
    getAllDemandesEntreprise();
  }, []);

  function getAllDemandes() {
    Tia.Demande.get().then((res) => {
      if (res.data) {
        setLoader(false);
        setDataDemander(res.data);
      }
    });
  }

  function getAllDemandesEntreprise() {
    Tia.Entreprise.get().then((res) => {
      if (res.data) {
        setLoader2(false);
        setDataEntreprise(res.data);
      }
    });
  }

  function demandeurs() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          title="table des demandes d'emplois "
          customHeader={["Titre de l'emploi", "Entreprise", "ville", "Actions"]}
          data={dataDemander}
          renderItem={(item, key) => (
            <DemanderComponent data={item} id={key} refresh={getAllDemandes} />
          )}
        />
      );
    }
  }

  function entreprises() {
    if (loader2) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          title="tableau des entreprises"
          customHeader={["Nom de l'entreprise", "Numéro de contact", ""]}
          data={dataEntreprise}
          renderItem={(item, key) => (
            <Recru data={item} id={key} refresh={getAllDemandesEntreprise} />
          )}
        />
      );
    }
  }

  return (
    <div>
      <Header />

      <div className="wrapper d-flex align-items-stretch">
        <Nav />
        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <h3>Gestion des demandes</h3>

            <ul
              className="nav nav-pills mb-5 mt-5"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => getAllDemandes()}
                >
                  Demandeurs d'emplois
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => getAllDemandesEntreprise()}
                >
                  Entreprises
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {demandeurs()}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {entreprises()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Get;
