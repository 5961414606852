import React, { useEffect } from "react";
import Nav from "../../components/Nav";
import DataTable from "../../components/DataTable";
import EntrepriseComponent from "../../components/EntrepriseComponent";
import Tia from "../../constants/Api";
import { Link } from "react-router-dom";
import Header from "../../components/Header";

const Entreprise = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);

  useEffect(() => {
    getEntreprise();
  }, []);

  function getEntreprise() {
    Tia.Entreprise.get().then((res) => {
      if (res.data) {
        setLoader(false);
        setData(res.data);
      }
    });
  }

  function Reload(bool) {
    if (bool) {
      getEntreprise();
    }
  }

  function renderEntreprise() {
    if (loader) {
      return (
        <div className="row justify-content-center mt-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <DataTable
          className="table  table-hover"
          title="Liste des entreprises"
          customHeader={["", "Nom", "Statut", "Actions"]}
          data={data}
          renderItem={(item, key) => (
            <EntrepriseComponent data={item} id={key} reload={Reload} />
          )}
        />
      );
    }
  }

  return (
    <div>
      <Header />

      <div className="wrapper d-flex align-items-stretch">
        <Nav />
        <div id="content" className="p-4 p-md-5 pt-5">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="#">
                <h3>Gestion des entreprises</h3>
              </a>

              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link
                    className="nav-link btn btn-primary btn-sm text-white"
                    to="/rapport/entreprises"
                  >
                    Rapport
                  </Link>
                </li>
              </ul>
            </nav>

            {renderEntreprise()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entreprise;
