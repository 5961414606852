import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const url ='https://backend.group-tia.com';
export const src ="https://backend.group-tia.com/uploads/";

// dashCount

export function isEmpty(data) {
    for (let item in data){
        return false
    }
    return true
}

export function notify (bool){
    bool === true ? toast.success("Operation reussie",{autoClose:3000}):toast.error("Echec de l'opération",{autoClose:3000});
}

export function printDocument(divName) {
    var printContents = document.getElementById(divName).innerHTML;    
    var originalContents = document.body.innerHTML;      
    document.body.innerHTML = printContents;     
    window.print();     
    document.body.innerHTML = originalContents;
}