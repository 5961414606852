import React from 'react';
import PropTypes from 'prop-types';

import './style.css'
import {isEmpty, notify} from '../../constants/constants';
import ButtonLoader from '../../components/ButtonLoader';
import Tia from "../../constants/Api";

const Recru = (props) => {

    const[items, setItem] = React.useState([]);
    const[loader, setLoader] = React.useState(true);
    const[status, setStatus] = React.useState("0");
    const[idContacter, setIdContacter] = React.useState();
    const[isLoading, setIsLoading] = React.useState(false);
    const[message, setMessage] = React.useState(false);
    const[error, setError] = React.useState(false);


    const {data, refresh, id} = props

    function getItems(params) {
        Tia.Entreprise.getbyid(params).then(res => {
            if(res.data){
                setItem(res.data);
                setLoader(false)
            }
        })
    }

    function onSubmit(event) {

        event.preventDefault();
        setIsLoading(true);
        let formData = new FormData(event.target);
        formData.append('etat',status);

        Tia.Entreprise.updateRecru(idContacter,formData).then(res=>{
            if (res.data){
                setIsLoading(false);
                notify(true);
                refresh();

            }
        }).catch(error=>{

            notify(false);
            setIsLoading(false);
        })

    }

    function renderItems() {

        if (loader) {
            return(
                <div className="row justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }

        else{

            if (!isEmpty(items)) {

                if(status === "0"){
                    const Item = items.contacters.map((el, key)=>{

                        return el.etat === 0 || el.etat === 1 ?
                            <div className="card mb-3" key={key}>
                                <div className="card-body">

                                    <div className="row ">
                                        <div className="col-12 col-md-12">

                                            <div className="d-inline-flex ">
                                                <span className="fa fa-user-circle-o " style={{color:'#000', fontSize:30}} />

                                                <span className="h5 ml-4">{el.user.nom} {el.user.prenom}</span>
                                            </div>

                                            <div className="row mb-2">
                                                <div className="col-6 col-md-6">
                                                    <span className=" ">{el.user.email} </span>
                                                </div>
                                                <div className="col-6 col-md-6">
                                                    <span className="ml-4">{el.user.phone}</span>
                                                </div>
                                            </div>



                                            <div className="d-flex justify-content-end">

                                                <button className="btn btn-success btn-sm mr-2"
                                                        type="button"
                                                        onClick={()=>{
                                                            setStatus("2");
                                                            setIdContacter(el.id);
                                                        }}>
                                                    Accepter
                                                </button>

                                                <button className="btn btn-danger btn-sm "
                                                        type="button"
                                                        onClick={()=>{
                                                            setStatus("3");
                                                            setIdContacter(el.id);
                                                        }}>
                                                    Rejeter
                                                </button>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                            : <></>

                    });
                    return(
                        <>
                            {Item.length > 0 ? Item : <><p>Aucun recrutement disponible</p></>}

                        </>

                    )
                }
                else if(status === "2"){
                    return(
                        <div className="card">
                            <div className="mt-1 mr-2">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={()=>setStatus("0")} >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <label for="exampleFormControlTextarea1">E-mail pour la société</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" name="message_entreprise" rows="3"></textarea>
                                    </div>

                                    <div className="form-group mt-5">
                                        <label for="exampleFormControlTextarea1">E-mail pour le demandeur d'emploi</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" name="message_client" rows="3" ></textarea>
                                    </div>

                                    <ButtonLoader type="submit" title="Confirmer"  className="btn btn-primary col text-center mt-3" onLoad={isLoading}/>

                                </form>
                            </div>
                        </div>
                    )
                }

                else if(status === "3"){
                    return(
                        <div className="card">
                            <div className="mt-1 mr-2">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={()=>setStatus("0")} >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <form onSubmit={onSubmit}>
                                    <div className="form-group">
                                        <label for="exampleFormControlTextarea1">E-mail pour le demandeur d'emploi</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="message_client"></textarea>
                                    </div>
                                    <ButtonLoader type="submit" title="Confirmer"  className="btn btn-primary col text-center mt-3" onLoad={isLoading}/>
                                </form>

                            </div>
                        </div>
                    )
                }

            }


        }

    }

    return (

        <tr key={id}>

            <td>{data.nom_entreprise}</td>

            <td>{data.contact}</td>

            <td>
                <button
                    type="button"
                    className="btn btn-success btn-sm ml-2 d-inline-flex"
                    onClick={()=>getItems(data.id)}
                    data-toggle="modal" data-target={"#modalE" + data.id}

                >
                    <i className="fa fa-eye pt-1" aria-hidden="true"></i> <span className="ml-1">Détails</span>
                </button>
            </td>

            <div className="modal fade" id={"modalE"+data.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row">

                                <div className="col">
                                    <h5>{data.nom_entreprise}</h5>
                                    <p style={{color:'tomato'}}>{data.nom_entreprise}</p>


                                </div>



                                <div className="col ex1">

                                    {renderItems()}
                                    {
                                        message?<div className="alert alert-success alert-dismissible fade show mt-2" role="alert">
                                            <strong>Operation effectuer avec succes</strong>
                                            <button type="button" className="close" onClick={()=>setMessage(false)}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>:null
                                    }

                                    {
                                        error?<div className="alert alert-error alert-dismissible fade show mt-2" role="alert">
                                            <strong>Une erreur est survenue lors de l'operation</strong>
                                            <button type="button" className="close" onClick={()=>setError(false)}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>:null
                                    }



                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>

        </tr>
    );
}

Recru.propTypes = {
    data: PropTypes.object.isRequired,

};


export default Recru;
