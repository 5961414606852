import React from "react";
import ButtonLoader from "../../components/ButtonLoader";
import { Redirect } from "react-router-dom";
import Tia from "../../constants/Api";

const Login = () => {
  const [loader, setLoader] = React.useState(false);
  // const[email, setEmail] = React.useState(null);
  // const[password, setPassword] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [display, setDisplay] = React.useState(false);

  function onLogin(event) {
    event.preventDefault();
    setLoader(true);
    setError(false);
    let formData = new FormData(event.target);

    Tia.Auth.login(formData)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          sessionStorage.setItem(
            "userFullname",
            res.data.user.prenom + " " + res.data.user.nom
          );
          sessionStorage.setItem("userId", res.data.user.id);
          sessionStorage.setItem("token", res.data.token);
          setLoader(false);
          setDisplay(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (display) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-lg-6 mt-5">
          <div className="card p-5">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  <img
                    src="http://job.group-tia.com//tia.png"
                    alt="logo du Groupe TIA"
                    width="100"
                  />
                </div>

                <div className="card-body">
                  <form onSubmit={onLogin}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">
                        Adresse e-email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        name="username"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Mot de passe
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                      />
                    </div>

                    <ButtonLoader
                      type="submit"
                      title="Connexion"
                      className="btn btn-primary col text-center mt-3"
                      onLoad={loader}
                    />
                  </form>

                  {error ? (
                    <div className="alert alert-danger mt-3 ">
                      Adresse e-mail ou mot de passe incorrecte
                      <button
                        type="button"
                        className="close"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
