import React from 'react';
import Tia from "../../constants/Api";
import {notify} from "../../constants/constants";


const JobComponent = (props) => {

    const {data, id, reload} = props;
    const [send, setSend] = React.useState(false);

    
    function active(idJob, etat) {

        setSend(true);
        let formData = new FormData();
        formData.append("active", etat);
        Tia.Job.update(idJob, formData)
            .then((res) => {
                if (res.data) {
                    setSend(false);
                    notify(true);
                    reload(true);
                }
            })
            .catch((error) => {
                if (error) {
                    setSend(false);
                    notify(false);
                }
            });
    }

    function supprimer(idJob) {

        setSend(true);
        let formData = new FormData();
        formData.append("is_delete", true);
        Tia.Job.delete(idJob, formData)
            .then((res) => {
                if (res.data) {

                    setSend(false);
                    notify(true);
                    reload(true)
                }
            })
            .catch((error) => {
                if (error) {
                    setSend(false);

                    notify(false);
                }
            });
        // setSend(false)
    }

    return (
        <tr>
            <td><span className="fa fa-briefcase mr-3" style={{color:'#000', fontSize:30}}></span></td>
            <td className="text-uppercase">{data.job_title.name}</td>
            <td className="text-uppercase">{data.entreprise.nom_entreprise}</td>
            <td className="text-uppercase">{data.vile}</td>
            <td>{data.active_until.split("T")[0]}</td>

            <td>
                {
                    data.active ===  1 ? <span className="badge badge-primary">Approuvé</span> :
                        data.active === 0?<span className="badge badge-warning">En attente</span>:
                            <span className="badge badge-danger">Rejeté</span>}
            </td>

            <td className="d-flex justify-content-around">

                <button
                    type="button"
                    className="btn btn-success btn-sm d-inline-flex"
                    data-toggle="modal" data-target={"#modal"+id}

                >
                    <i class="fa fa-eye pt-1" aria-hidden="true"></i> <span className="ml-1">Détails</span>
                </button>
                <button
                    type="button"
                    className="btn btn-danger btn-sm ml-2 d-inline-flex"
                    onClick={()=>supprimer(data.id)}
                >
                    { send ? <span id={data.id}
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                    /> : <><i className="fa fa-trash pt-1" aria-hidden="true" /> <span
                        className="ml-1">Supprimer</span></> }

                </button>
            </td>


            <div className="modal fade" id={"modal"+id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Détails sur l'emploi</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="container">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="tia-color">
                                                    {data.job_title.name}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                {
                                                    data.entreprise.image === null
                                                    ? "Logo de l'entreprise "
                                                    : data.entreprise.image
                                                }
                                            </div>
                                            <div className="col-9">
                                                <p>Entreprise : {data.entreprise.nom_entreprise}</p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-4">
                                                <p>
                                                    Date de création :<br />
                                                    <span className="tia-color font-weight-bold">
                                      {data.createdAt.split("T")[0]}
                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p>
                                                    Date limite de candidature : <br />
                                                    <span className="tia-color font-weight-bold">
                                      {data.active_until.split("T")[0]}
                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-4">
                                                <p>
                                                    Ville :<br />
                                                    <span className="tia-color font-weight-bold">
                                      {data.vile}
                                    </span>
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <h4>Description : </h4>
                                                <p className="text-justify">
                                                    {data.desciption}
                                                </p>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h4>Secteurs : </h4>
                                                <ul className="list-skills">
                                                    {data.secteurs.map((el, key) => {
                                                        return (
                                                            <li key={key}>{el.nom_secteur}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div className="col-12">
                                                <h4>Compétences : </h4>
                                                <ul className="list-skills">
                                                    {data.skills.map((el, key) => {
                                                        return <li key={key}>{el.name}</li>;
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="modal-footer">


                            {
                                data.active ===  1 ?<button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={() => active(data.id, 2)}
                                >
                                    Rejeter
                                </button>
                                    :
                                    data.active ===  0 ? <>

                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        data-dismiss="modal"
                                        onClick={() => active(data.id, 1)}
                                    >
                                        Approuver
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-dismiss="modal"
                                        onClick={() => active(data.id, 2)}
                                    >
                                        Rejeter
                                    </button>
                                </>:data.active === 2? <button
                                    type="button"
                                    className="btn btn-success"
                                    data-dismiss="modal"
                                    onClick={() => active(data.id, 1)}
                                >
                                    Approuver
                                </button>:null
                            }



                        </div>
                    </div>
                </div>
            </div>


        </tr>
    );
}

export default JobComponent;
