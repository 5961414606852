import React from "react";

const ChildTable = ({ datas, config, page, number, search, filterMany }) => {
  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  return (
    <tbody>
      {paginate(filter(datas, search, config, filterMany), page, number).map(
        (element, key) => (
          <ItemRow key={key} element={element} config={config} />
        )
      )}
    </tbody>
  );
};

const ItemRow = ({ element, config }) => {
  return (
    <tr>
      {config.map((head, key) => {
        if (head.visible) {
          return (
            <td key={key}>
              {typeof element[head.name] !== "object"
                ? element[head.name]
                : Array.isArray(element[head.name])
                ? element[head.name].map((el, elkey) => {
                    return (
                      <button
                        key={elkey}
                        type="button"
                        className="btn btn-outline-primary btn-sm mr-1"
                      >
                        {Array.isArray(head.options.value)
                          ? head.options.value.map((el) => {
                              if (element[head.name]) {
                                return element[head.name][el] + el + " | ";
                              }
                            })
                          : typeof el[head.options.value] !== "object" &&
                            el[head.options.value]}
                      </button>
                    );
                  })
                : head && "options" in head
                ? head.options.value.map((el, key) => {
                    if (element[head.name]) {
                      if (key === head.options.value.length - 1) {
                        return element[head.name][el];
                      }
                      return element[head.name][el] + " | ";
                    }
                  })
                : null}
            </td>
          );
        }
      })}
    </tr>
  );
};

function isNotEmpty(data) {
  for (let item in data) {
    return true;
  }
  return false;
}

export function filter(
  elements,
  filter_search,
  filter_config,
  filter_filterMany
) {
  let rows = [];

  if (filter_search.length > 0) {
    elements.map((element) => {
      for (let key in filter_config) {
        if (filter_config.hasOwnProperty(key)) {
          if (typeof element[filter_config[key].name] === "string") {
            if (
              element[filter_config[key].name]
                .toLowerCase()
                .indexOf(filter_search.toLowerCase()) !== -1
            ) {
              rows.push(element);
              break;
            }
          } else if (typeof element[filter_config[key].name] === "object") {
            if (Array.isArray(element[filter_config[key].name])) {
            } else {
              for (let nalembi in element[filter_config[key].name]) {
                if (element[filter_config[key].name].hasOwnProperty(nalembi)) {
                  if (element[filter_config[key].name][nalembi]) {
                    if (
                      typeof element[filter_config[key].name][nalembi] ===
                      "string"
                    ) {
                      if (
                        element[filter_config[key].name][nalembi]
                          .toLowerCase()
                          .indexOf(filter_search.toLowerCase()) !== -1
                      ) {
                        rows.push(element);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });
  } else {
    rows = elements;
  }

  let passage = [];
  let enterpassage = false;
  for (let key_rows in rows) {
    if (rows.hasOwnProperty(key_rows)) {
      for (let key in filter_config) {
        if (filter_config.hasOwnProperty(key)) {
          if (filter_filterMany) {
            for (let manys in filter_filterMany[filter_config[key].name]) {
              if (
                filter_filterMany[filter_config[key].name].hasOwnProperty(manys)
              ) {
                enterpassage = true;

                if (
                  typeof rows[key_rows][filter_config[key].name] !== "object"
                ) {
                  if (
                    rows[key_rows][filter_config[key].name] ===
                    filter_filterMany[filter_config[key].name][manys]
                  ) {
                    passage.push(rows[key_rows]);
                    break;
                  }
                } else if (
                  Array.isArray(rows[key_rows][filter_config[key].name])
                ) {
                  for (let arraykey in rows[key_rows][
                    filter_config[key].name
                  ]) {
                    if (
                      rows[key_rows][filter_config[key].name].hasOwnProperty(
                        arraykey
                      )
                    ) {
                      if ("options" in filter_config[key]) {
                        if ("value" in filter_config[key].options) {
                          if (Array.isArray(filter_config[key].options.value)) {
                            // for(let valuekey in filter_config[key].options.value){
                            //   if(filter_config[key].options.value.hasOwnProperty(valuekey)){
                            //       if (
                            //         rows[key_rows][filter_config[key].name][filter_config[key].options.value[valuekey]] ===
                            //         filter_filterMany[filter_config[key].name][manys]
                            //       ) {
                            //         passage.push(rows[key_rows]);
                            //         break;
                            //       }
                            //   }
                            // }
                          } else {
                            if (
                              rows[key_rows][filter_config[key].name][arraykey][
                                filter_config[key].options.value
                              ] ===
                              filter_filterMany[filter_config[key].name][manys]
                            ) {
                              passage.push(rows[key_rows]);
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  if ("options" in filter_config[key]) {
                    if ("value" in filter_config[key].options) {
                      if (Array.isArray(filter_config[key].options.value)) {
                        for (let valuekey in filter_config[key].options.value) {
                          if (
                            filter_config[key].options.value.hasOwnProperty(
                              valuekey
                            )
                          ) {
                            if (rows[key_rows][filter_config[key].name]) {
                              if (
                                rows[key_rows][filter_config[key].name][
                                  filter_config[key].options.value[valuekey]
                                ] ===
                                filter_filterMany[filter_config[key].name][
                                  manys
                                ]
                              ) {
                                passage.push(rows[key_rows]);
                                break;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  if (enterpassage) {
    rows = passage;
  }

  return rows;
}

export default ChildTable;
